import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    component: () => import('@/views/home'),
    meta: { title: '放映厅' },
    beforeEnter: (to, from, next) => {
      const viewer = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      viewer ? next() : next('/pc-home')
    }
  },
  {
    path: '/pc-home',
    component: () => import('@/views/pc'),
    meta: { title: '放映厅 - 仅支持移动端访问' },
    beforeEnter: (to, from, next) => {
      const viewer = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      viewer ? next('/home') : next()
    }
  },
  {
    path: '/play/:id',
    meta: { title: '放映厅 - 播放' },
    component: () => import('@/views/play')
  },
  {
    path: '*',
    redirect: '/home'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: "/",
  routes
})

router.beforeEach((to, from, next)=>{
  window.document.title = to.meta.title
  next()
})

export default router
