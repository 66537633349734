<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  created(){
    console.log('%c扒别人网站是不对滴，虽然我也是扒别人的，嘿嘿', 
    `
    font-family: "Microsoft YaHei",微软雅黑;
    padding: 30px;
    margin: 30px;
    background: #6185f4;
    color: #ffffff;
    border-radius: 15px;
    font-size: 20px;
    font-weight: bolder;
    `)
  }
}
</script>

<style lang="scss">
*{
  padding: 0;
  margin: 0;
  font-family: "Microsoft YaHei",微软雅黑;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#app {
  width: 46.875rem;
  text-align: center;
  color: #273747;
}
</style>
